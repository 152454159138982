:root {
  --bg-dark: #090909 ;
  --bg-light: #F5F5F5;
  
  --text-dark: #090909;
  --text-light: #F5F5F5;
  --text-grey: #CFDCEC;
  --text-yellow: #E0B225;
  --text-green: #1D9E95;
  --text-orange: #E27861;
  --text-orange-light: #DFAB9F;

  --border-light: #F5F5F5;
  --border-dark: #010103;

  --button-light: var(--border-light);
  --button-dark: var(--border-dark);

  --header-height: 100px;
  --body-height: calc(100vh - var(--header-height));
}

/* body */

body{
  background-color: #F5F5F5;
  overflow-x: hidden;

}

.dark_background{
  background-color: var(--bg-dark) !important;
}

.light-background{
  background-color: var(--bg-light) !important;
}

/*width*/

  .w-30{
    width: 30px;

  }

  .w-100{
    max-width: 100vw;
  }

  .w-150px{
    max-width: 150px;
  }


  .w-200px{
    max-width: 200px !important;
  }

  .header-height{
    height: var(--header-height);
  }


  .header {
    position: absolute !important;
    top: 0;
    left: 0;
    width: 100%;
    z-index: -9;
  }

  .menu{
    position: absolute !important;
    background-color: var(--bg-light);
    z-index: 3;

  }

  .container-fluid {
    display: flex;
    align-items: flex-start; /* Align items to the top */
    justify-content: space-between; /* Spread items to the ends */
  }
  
  .logo-container {
    display: flex;
    align-items: flex-start; /* Align logo to the top */
  }
  
  .menu-icon-container {
    display: flex;
    align-items: flex-start; /* Align menu icon to the top */
    margin-top: 0; /* Add or adjust margin if necessary */
  }


  @media (max-width: 576px) {  
    .header {
      width: 100vw;
      height: 100px !important;
      position: relative !important;
      padding: 0 !important;
    }


  
    .menu-icn{
      display: block;
      position: absolute;
      width: 35px;
      top: 0;
      right: 10px;
    }
        


  }
  

 /* screen-heights */

  .h-100{
    min-height: 100vh;
    }

  .h-100px{
    height: 100px;
  }

  .w-300px{
    width: 200px !important;
  }


  .h-90{
    min-height: 90vh;
  }

  .h-80{
    min-height: 80vh;
  }


  /*fonts*/



  a{
    text-decoration: none !important;
    cursor: pointer !important;
    color: #010103 !important;
  }
  
  p{
    font-family: mundial, sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
    font-size: 1.3rem !important;
  }
  
  h1 {
    font-family: mundial, sans-serif;
    font-size: 1.3rem !important;
    line-height: 1.1 !important;
  }
  
  
  
  h2{
    font-family: mundial, sans-serif;
    font-size: 2.2rem !important;
    line-height: 1.1 !important;
  }
  
  
  h4{
    font-family: mundial, sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
    font-size: 24px !important;
    line-height: 1.1;
  }
  
  
  h5{
    font-family: mundial, sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
    font-size: 18px !important;
    line-height: 1.1;
  }
  
  
  h6{
    font-family: mundial, sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
    font-size: 18px !important;
    line-height: 1.1;
  }
  
  
  @media (max-width: 768px) {
    h1 {
      font-size: 3rem !important;  /* Adjust this for smaller screens */
    }
  }
  
  @media (max-width: 576px) {
    h1 {
      font-size: 2.2rem !important;  /* Further adjust for very small screens */
    }
  }
  
  


.capitalize{
  text-transform: capitalize;
}

.pointer{
  cursor: pointer;
}

.cursor-none{
  cursor: none;

}



@media (max-width: 1023px) {
  .fs-60 {
    font-size: 36px; 
  }
}


.fw-100{
  font-weight: 100;
}

.fw-200{
  font-weight: 200;
}

.fw-300{
  font-weight: 300;
}

.fw-400{
  font-weight: 400;
}

.fw-500{
  font-weight: 500;
}

.fw-600{
  font-weight: 600;
}

.fw-700{
  font-weight: 700;
}
.text-dark{
  color: var(--text-dark);
}

.text-light{
  color: var(--text-light);
}


/* btns */

.btn-clean-primary{
  background-color: none !important;
  padding: 0 !important;
  margin: 0 auto !important;
  border: 0;
  background-color: var(--bg-light);
  font-size: 1.1rem !important;
  font-weight: 400;
}



li{
  list-style: none;
}

/* images */

.artworks{
  padding: 10px;
}

.image-container {
  position: relative;
  display: inline-block; /* Keeps the container inline with text */
}

.overlay-text {
  bottom: 0px;
  padding: 0 20px;
  z-index: 10;   
}

/* borders */

.border-bottom-dark{
  border-bottom: 3px solid var(--border-dark)
}

.border-bottom-dark-1px{
  border-bottom: 1px solid var(--border-dark)
}


.container{
  position: relative;
  z-index: 1;
}

.bg-video video{
  height: 100%;
  width: 100%;
  overflow: none;
  position: absolute;
  z-index: 0;
  opacity: 0.35;

}

.container_unity {
  position: relative;

}

.container_unity > .loading-overlay {
  /* We'll render the overlay on top of the Unity Application. */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--bg-dark);
  /* We'll set the following Flex properties in order to center the text. */
  display: flex;
  justify-content: center;
  align-items: center;
}

.container_unity > .unity {
  /* The Unity Application matches it size to the container. */
  width: 100%;
  height: 100%;
}


.w-250px{
  width: 250px !important;
}


.skip_btn{
  position: absolute;
  z-index: 9;
  bottom: 0;
  right: 0;
  color: var(--bg-light);
}

.rotate-180 {
  transform: rotate(180deg);
}

.active {
  font-weight: bold;
}

@media screen and (min-width: 768px) {
  .img-video-replacement {
    display: none;
  }
}



/* sliders */


.slider {
  /* background-color: #010103; */
  position: relative;
  width: 100%;
  margin: 0;
}

.slider-video {
  width: 100%;
  height: 0;
  padding-top: 56.25%; 
  position: relative;
}

.slider-video video {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transition: opacity 1s;

}

.slider-video video.active {
  opacity: 1;
  z-index: 1;
}

.slider-video video.inactive {
  opacity: 0;
  z-index: 0;
}

.slider-button {
  position: absolute;
  background-color: #01010300;
  top: 50%;
  transform: translateY(-50%);
  border: none;
  cursor: pointer;
  z-index: 4;
}

.prev {
  left: 20px;
}

.next {
  right: 20px;
}


/* ARROWS */


.floating-arrow {
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  font-size: 2rem;
  cursor: pointer;
  background-color: var(--text-light);
  border-radius: 20px;
  padding: 0 10px;
  margin: 0 10px;
  z-index: 999;
}

.left-arrow {
  left: 0;
  padding-left: 10px; /* Adjust padding as needed */
}

.right-arrow {
  right: 0;
  padding-right: 10px; /* Adjust padding as needed */
}


/* @media screen and (max-width: 768px) {
  .floating-arrow {
    font-size: 2rem;
  }
} */


